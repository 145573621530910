import store from 'store';

export const setBackendEnvironment = ({
	rest = '',
	graphql = ''
}: {
	rest?: string;
	graphql?: string;
}): void => {
	if (rest.length > 0) {
		store.set('REACT_APP_API', rest);
	}

	if (graphql.length > 0) {
		store.set('REACT_APP_GRAPHQL_URI', graphql);
	}

	if (rest?.length > 0 || graphql?.length > 0) {
		window.location.reload();
	}
};

export const getBackendEnvironment = (): { rest: string; graphql: string } => ({
	rest:
		store.get('REACT_APP_API') ??
		window.apiUrl ??
		process.env.REACT_APP_API ??
		window.location.origin,
	graphql:
		store.get('REACT_APP_GRAPHQL_URI') ??
		window.graphqlUrl ??
		process.env.REACT_APP_GRAPHQL_URI ??
		window.location.origin
});
